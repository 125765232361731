.App {
  /* text-align: center; */
  background-color: black;
  color: white;
  min-height: 100vh;
}
#deckgl-overlay {
  position: relative !important;
}
#deckgl-wrapper {
  position: relative !important;
}

.home-intro {
  display: flex;
  justify-content: center;
  text-align: center;
  min-height: 86vh;
  flex-direction: column;
}

.home-intro p:last-child {
  font-size: 1.9rem;
}
/* 
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.icon-nav {
  font-size: 25px;
}
.job-listing {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: auto;
  width: 90%;
  padding: 20px;
}

form {
  color: #555;
  /* display: flex; */
  width: 95%;
  padding: 2px;
  margin: 0 auto;
}

input[type="text"] {
  border-radius: 15px;
}
.text-larger {
  font-weight: 600;
  font-size: 1.5rem;
}
.job-listing h3 {
  color: white;
  word-wrap: normal;
}
.job-header {
  text-transform: uppercase;
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 600;
}
.job-listing hr {
  width: 50%;
  margin: 10px auto;
}
.job-block {
  text-align: center;
  margin-bottom: 80px;
}
.job-title-base {
  height: 30vh;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
}

.home-text {
  font-weight: 200;
  font-size: 16px;
  letter-spacing: 1px;
}
.home-base {
  background-color: rgba(0, 0, 0, 0.877);
  width: 75%;
  border: rgb(77, 77, 77) solid 1px;
  margin: auto;
}
.home-base2 {
  min-height: 250px;
  background-color: rgba(0, 0, 0, 0.822);
  padding: 10px;
  border: rgb(77, 77, 77) solid 1px;
}

.about-icon {
  font-size: 30px;
  margin-right: 10px;
}
a.about-link {
  color: white;
}
.job-title {
  background-color: white;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  color: black;
  padding: 20px;
  box-shadow: 0 5 5 2rem rgb(0, 0, 0);
  text-align: center;
  margin: auto;
  transform: "translate(-50%,-50%)";
}

.about-base {
  min-height: 100%;
}

.aboutSections {
  /* min-height: 85vh; */
  padding: 70px 0;
}
.bouroughs {
  display: inline;
  /* padding: 10px; */
  color: white;
}
.bouroughs-base {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}
.map-container {
  position: relative;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.job-center-base {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: auto;
  width: 100%;
  column-gap: 50px;
  row-gap: 90px;
  justify-content: center;
  padding: 30px 30px 100px;

  text-align: center;
}

.job-center-base hr {
  width: 50%;
  margin: 10px auto;
}
.mapalt-container {
  position: relative;
  height: 50vh;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.marker-label p {
  text-align: center !important;
}
.marker-label {
  display: flex;
  justify-content: center;
}
.marker {
  /* background-image: url('svg/marker.svg'); */
  background-size: cover;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
}

.modalInfo {
  display: grid;
}

.mainMap {
  display: flex;
  justify-content: center;
}

.mapbox-improve-map {
  display: none;
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width: 320px) and (max-width: 600px) {

  .home-intro{
  min-height: 70vh;
  }
  .job-center-base {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    padding: 30px 30px 100px;
  }
  .job-listing {
    grid-template-columns: 1fr;
    gap: 0;
    margin: 0;
    width: 95%;
    padding: 10px;
  }
  .job-block hr {
    width: 50%;
    margin: 5px 0;
  }
  .job-block {
    /* border-bottom: 1px solid white; */

    width: 100%;
    margin: 25px 0;
    text-align: left;
  }

  .job-header {
    text-transform: uppercase;
    word-wrap: normal;
    font-size: 1.2rem;
    font-weight: 600;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 601px) and (max-device-width: 1024px) {
  .job-listing {
    grid-template-columns: 1fr 1fr;
    gap: 0;
    margin: 0;
    width: 95%;
    padding: 10px;
    width: 95%;
  }

  .job-block {
    /* border-bottom: 1px solid white; */

    margin: 30px 0;
    text-align: center;
  }

  .job-header {
    text-transform: uppercase;
    word-wrap: normal;
    font-size: 1rem;
    font-weight: 600;
  }

  .job-center-base {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    padding: 30px 30px 100px;
  }
}
